<template>
  <div class="text-left">
    <!-- <BaseHeader :title="'bonus'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else class="pt-4">
      <div class="row" v-if="bonusTotal && bonusTotal > 0">
        <div class="col col-12 col-sm-6 col-md-3">
          <v-alert
            class="rounded"
            color="success"
            prominent
            outlined
            dense
            text
            type="success"
          >
            <h2 class="font-weight-bold">$ {{ bonusTotal || 0.0 }}</h2>
            <span class="text-capitalize"> Total </span>
          </v-alert>
        </div>
      </div>

      <div class=" mt-2 border-0">
        <div class="card p-3 d-flex align-items-center flex-row w-100 justify-content-between text-right d-flex" style>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter Bonus"
            name="url"
            type="text"
            hide-details
          />
          <v-spacer></v-spacer>

          <div class="w-50 ">
            <button
            class="my-auto float-right btn-add ml-2"
            v-if="userType != userTypes.writer"
            @click.stop="
              edit = false;
              bonusForm.reset();
              $store.state.dialog = true;
              "
            >Add new Bonus</button>
          </div>
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="bonusHeaders"
          :items="bonus"
          :search="$store.state.search"
          :disable-pagination="true"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.amount`]="{ item }">
            ${{ item.amount || 0.0 }}
          </template>
          <template v-slot:[`item.order_id`]="{ item }">
            <router-link
              v-if="item.order_id != '-1'"
              :to="{ name: 'Order', params: { id: item.order_id } }"
              >{{ item.order_id || "" }}</router-link
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="error"
              class="white--text"
              icon
              v-if="userType != userTypes.writer"
              @click="
                editMode(item);
                edit = true;
                $store.state.dialog = true;
              "
            >
            <button class=" btn  btn-outline-danger rounded " >Delete</button>

            </v-btn>
          </template>
        </v-data-table>
        <!-- /datatable -->

        <!-- pagination -->


        <div class="card d-flex mb-3 align-items-center flex-row w-100 justify-content-between p-3 mb-3" v-if="bonus.length > 0">
          <p class="text-left">
            From {{ bonusFilters.from || "" }} to {{ bonusFilters.to || "" }} of
            {{ bonusFilters.total || "" }} Bonus
          </p>
            <div data-v-2bb62016="" class="btn-group">
              <button   @click="navigate(bonusFilters.prev_page_url)"  v-if="bonusFilters.prev_page_url" class="btn btn-outline-primary btn-lg">← &nbsp; Prev</button>
              <button   class="btn btn-primary btn-lg">On Page {{ bonusFilters.current_page || "" }}</button>
              <button   @click="navigate(bonusFilters.next_page_url)" v-if="bonusFilters.next_page_url" class="btn btn-outline-primary btn-lg">Next &nbsp; →</button>
            </div>
           
        </div>
        <!-- ./pagination -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left text-start"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="bonusForm.busy">
          <v-card-title class="text-h5">
            {{ edit ? `Delete Bonus` : "New Bonus" }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <div v-if="edit" class="text-left">
              <p>
                Are you sure you want to delete '{{
                  bonusForm.description || ""
                }}
              </p>

              <v-card-actions>
                <v-btn color="error darken-1" text @click="deleteBonus">
                  Yes, Delete
                </v-btn>
                <v-btn color="" text @click="$store.state.dialog = false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
            <form
              v-else
              class="flex-fill text-left"
              ref="bonusForm"
              @submit.prevent="createBonus"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Description"
                      name="description"
                      v-model="bonusForm.description"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="bonusForm"
                            class="v-messages theme--light error--text"
                            field="description"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      style="text-align: left !important"
                      class="text-start mb-4"
                      :items="writers"
                      item-value="id"
                      item-text="first_name"
                      v-model="bonusForm.writer_id"
                      label="Select writer"
                      dense
                      outlined
                      hide-details
                      spellcheck="false"
                      :filter="filterObject"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.names }} - #{{ data.item.id }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.names }} - #{{ data.item.id }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Order ID"
                      name="order_id"
                      v-model="bonusForm.order_id"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="bonusForm"
                            class="v-messages theme--light error--text"
                            field="order_id"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                    <small class="primary--text"
                      >If the bonus is order based, enter an order ID. Otherwise
                      leave it as (-1)</small
                    >
                  </v-col>
                  <v-col cols="12" md="6" class="mb-2">
                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      name="amount"
                      type="number"
                      v-model="bonusForm.amount"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="bonusForm"
                            class="v-messages theme--light error--text"
                            field="amount"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="bonusForm.busy"
                    type="submit"
                    color="teal"
                    class="white--text"
                    >{{ edit ? "Update" : "Save Bonus" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import http from "../../modules/http.index";
export default {
  name: "bonus",
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("payment", [
      "bonusForm",
      "loading",
      "bonus",
      "bonusHeaders",
      "bonusTotal",
      "bonusFilters",
    ]),
    ...mapState("writers", ["writers"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
      activeItem: {},
    };
  },

  methods: {
    ...mapActions("payment", ["_getBonus"]),
    ...mapActions("writers", ["getAllWriters"]),
    filterObject(item, queryText) {
      return (
        item.names.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.id.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    createBonus() {
      console.log("bonusForm", this.bonusForm);
      this.edit
        ? this.bonusForm
            .put(`${this.$baseUrl}/Payments/Bonus`)
            .then(() => {
              this.$store.state.dialog = false;
             

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `Bonus updated successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this._getBonus();
            })
            .catch((err) => {
              console.log(err);
             

              this.$swal({
              icon: 'error',
              title: 'Error',
              text:  `Error while updating bonus`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            })
        : this.bonusForm
            .post(`${this.$baseUrl}/Payments/Bonus`)
            .then((res) => {
              this.$store.state.dialog = false;
              

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: "Bonus created successfully.",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this._getBonus();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: (err.response && err.response.data.Message) ||
              `Error while creating bonus`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            });
    },

    deleteBonus() {
      http
        .delete(`/Payments/Entry/${this.activeItem.id}`)
        .then(() => {
          this.$store.state.dialog = false;
          this.$notify({
            title: "Success",
            text: `Bonus deleted successfully.`,
            style: "success",
          });
          this._getBonus();
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data
              ? err.response.data.Message
              : `Error while deleting Bonus`,
            style: "danger",
          });
        });
    },

    editMode(item) {
      console.log(item);
      this.activeItem = item;
      Object.assign(this.bonusForm, { ...item });
    },
  },
  mounted() {
    this._getBonus();
    this.getAllWriters();
  },
};
</script>

